import React from 'react'
import './App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ProgressBar } from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import { faGithub, faGitlab, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import foto from "./images/foto.jpeg"
import 'bootstrap/dist/css/bootstrap.min.css'
import PortfolioCard from "./Component/Card"

function subtractYears() {
  return Math.abs(new Date().getFullYear() - 2016)
}
function App() {
  const scrollToSection = (id) => {
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <Container className="p-3">
      <div className="App">
        <header className="App-header">
          <nav>
            <ul className="header-ul">
              <li onClick={() => scrollToSection('about')}>About</li>
              <li onClick={() => scrollToSection('resume')}>Resume</li>
              <li onClick={() => scrollToSection('portfolio')}>Portfolio</li>
              <li onClick={() => scrollToSection('contact')}>Contact</li>
            </ul>
          </nav>
        </header>
        <main>
          <section id="about" className="section">
            <h2>About Me</h2>
            <div className='about-section'>
              <img alt="about-me" src={foto} className="profile-photo" />
              <p>{`I am a software engineer since 2016. With over 
                ${subtractYears()} years of experience in developing microservices applications using NodeJs, React, NextJs, C# and Java, I am passionate about creating efficient and scalable software that meets the needs of the users and the business.

                In my current role, I spearhead the development of microservices applications, emphasizing observability and performance optimization. I implement comprehensive observability solutions by integrating New Relic, facilitating real-time monitoring and troubleshooting. I also collaborate with cross-functional teams to design and implement features, ensuring alignment with project goals and best practices. Additionally, I proactively identify and resolve technical challenges, contributing to the overall success of the development process.`}</p>
            </div>
          </section>
          <section id="resume" className="section">
            <h2 >Education</h2>
            <div>
              <h3>PUC-MINAS</h3>
              <p >Electronic and Telecommunications Engineering</p>
              <h3>Ampli</h3>
              <p >Bachelor's degree Software Engineering, end date 2025</p>
              <h3>CEFET-MG</h3>
              <p >Electronics Technician Program</p>
              <h3>Colégio Augustus</h3>
              <p >Information Technology Technician Program</p>
            </div>
            <div className="resume-content">
              <h2 className='skills'>Skills</h2>
              <ProgressBar className="ProgressBar" striped variant="success" now={90} label="NodeJs" />
              <ProgressBar className="ProgressBar" striped variant="info" now={90} label="ReactJs" />
              <ProgressBar className="ProgressBar" striped variant="warning" now={80} label="NextJs" />
              <ProgressBar className="ProgressBar" striped variant="danger" now={90} label="C#" />
              <ProgressBar className="ProgressBar" striped variant="succes" now={80} label="Java" />
            </div>
          </section>
          <section id="portfolio" className="section">
            <PortfolioCard
              className="col-md-8"
              title="Challenges Projects"
              description="It's a single page web app to shows some Challenges that I had solved"
              externalLink="https://challenges.sistemaglobal.com.br/login"
            />
            <div className="row mt-2">
              <p> To test it, you can use demo credentials</p>
              <p>user: demoweather</p>
              <p>password:Weather(@#@)</p>
            </div>
          </section>
          <section id="contact" className="section">
            <h2>Contact Me</h2>
            <p>CLAUGER SODRÉ</p>
            <p>Software Developer</p>
            <p>+55 31 987068260</p>
            <p>claugergregory@gmail.com</p>
            <>
              {new Date().getFullYear()}
            </>
          </section>
        </main>
        <footer>
          <div className="social-icons">
            <a href="https://www.linkedin.com/in/clauger/" target="_blank" rel="noopener noreferrer">
            </a>
            <a href="https://github.com/claugersodre" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGithub} size="2x" />
            </a>
            <a href="https://gitlab.com/clauger_sodre" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faGitlab} size="2x" />
            </a>
            <a href="mailto:claugergregory@gmail.com">
              <FontAwesomeIcon icon={faEnvelope} size="2x" />
            </a>
            <a href="https://whatsa.me/5531987068260/?t=Hi">
              <FontAwesomeIcon icon={faWhatsapp} size="2x" />
            </a>
            <a href="https://www.linkedin.com/in/clauger/">
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
            </a>

          </div>
        </footer>
      </div>
    </Container>
  )
}

export default App
